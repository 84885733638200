/*
 * @Author: liuzhixiang 
 * @Date: 2021-02-06 15:19:01 
 * @Last Modified by: liuzhixiang
 * @Last Modified time: 2021-09-03 15:06:04
 */
<template>
  <div class="download_details">
    <Banner></Banner>
    <div class="width_1200">
      <div class="bread">
        <bread-crumb></bread-crumb>
      </div>
    </div>
    <div class="top">
      <div class="width_1200">
        <div class="top_cont">
          <img v-lazy="download.FileImg" class="tc_left" alt />
          <div class="tc_right">
            <el-row class="title" type="flex" align="middle">
              <el-col :span="24">{{ download.FileName }}</el-col>
            </el-row>
            <el-row class="glkc" type="flex" align="middle">
              <el-col :span="10">关联课程：{{ download.CourseName }}</el-col>
              <el-col :span="10">
                是否支持下载：
                {{ download.DownloadCategory == "1" ? "是" : "否" }}
              </el-col>
              <!-- <el-col :span="8">预览：</el-col> -->
            </el-row>
            <el-row class="glkc" type="flex" align="middle">
              <el-col :span="10"
                >更新时间：{{ download.UploadTime | dataYMD }}</el-col
              >
              <el-col :span="10">
                有效期：
                {{ download.FileValidityDateStart | dataYMD }}
                至
                {{ download.FileValidityDateEnd | dataYMD }}
              </el-col>
            </el-row>
            <el-row class="glkc" type="flex" align="middle">
              <el-col :span="8" class="c_F61717 fs_20">
                <span v-if="download.Category == 2" class="c_3ab57f">免费</span>
                <span v-else-if="download.IsSale != 1 && download.Category == 0"
                  >课程专属</span
                >
                <span v-else-if="download.IsSale != 1 && download.Category == 1"
                  >VIP专属</span
                >
                <span v-else-if="download.IsSale == 1"
                  >售价：￥{{ download.Price | priceToFixed }}</span
                >
              </el-col>
              <el-col :span="16" v-if="download.IsSale == 1">
                <el-button @click="buy(download)" class="buy"
                  >立即购买</el-button
                >
                <el-button @click="joinShop()" class="join"
                  >加入购物车</el-button
                >
              </el-col>
            </el-row>
            <el-row class="glkc" type="flex" align="middle">
              <!-- 
               -->
              <el-col
                :span="4"
                v-if="
                  download.DownloadCategory == '0' && download.Category == 2
                "
              >
                <!-- 下载： -->
                <el-button
                  class="download"
                  icon="el-icon-view"
                  size="mini"
                  round
                  @click="handlePreview(download.FileUrl)"
                  >预览</el-button
                >
              </el-col>
              <!-- 
                -->
              <el-col
                :span="4"
                v-else-if="
                  download.DownloadCategory == '1' && download.Category == 2
                "
              >
                <!-- 下载： -->
                <a
                  :href="download.FileUrl"
                  :target="download.FileName"
                  :download="download.FileName"
                >
                  <el-button
                    class="download"
                    icon="el-icon-download"
                    size="mini"
                    round
                    >下载</el-button
                  >
                </a>
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="cont">
          <div class="title">资料介绍</div>
          <div class="detail">
            <div v-if="download.Remark" v-html="download.Remark"></div>
            <div v-else>
              <no-data></no-data>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from "./components/banner";
import BreadCrumb from "@/components/breadcrumb.vue";
import { orderSaveCourseCar } from "@/api/order";
import { courseQueryCourseFileDetail } from "@/api/course";
import noData from "@/components/noData";

export default {
  data() {
    return {
      download: {},
    };
  },
  components: {
    Banner,
    BreadCrumb,
    noData,
  },
  created() {
    this.init();
  },
  mounted() {},
  methods: {
    // 加入购课车
    async joinShop() {
      let parm = {
        goodsId: this.download.Id,
        isVip: 0, //
        quantity: 1,
        GoodsCategory: 1, //0课程1资料2题库
      };
      const res = await orderSaveCourseCar(parm);
      if (res.success === true) {
        this.$message.success(res.msg);
      } else {
        this.$message.error(res.msg);
      }
    },
    //立即购买
    buy(obj) {
      var userInfo = JSON.parse(this.$store.state.userInfo);
      if (!userInfo.IsIdentificate) {
        this.$confirm("你还没实名认证不能购买课程?", "提示", {
          confirmButtonText: "去实名",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$router.push("/personal/personalData");
          })
          .catch(() => {});
        return false;
      }
      obj.IsVip = "0"; //是否Vip（1是0否）
      obj.GoodsCategory = 1; //商品类型（0课程1资料2题库）
      obj.Immediately = "1";
      this.$router.push({
        path: "/dataDownload/order",
        query: { data: JSON.stringify([obj]) },
      });
    },
    init() {
      let download = this.$route.query;
      this.getcourseQueryCourseFileDetail(download.Id);
    },
    //根据id获取资料详情
    async getcourseQueryCourseFileDetail(id) {
      const res = await courseQueryCourseFileDetail(id);
      if (res.success === true) {
        this.download = res.response;
      } else {
        this.$message.error(res.msg);
      }
    },
    handlePreview(url) {
      // let Base64 = require('js-base64').Base64;
      // window.open('http://121.36.221.33:8012/onlinePreview?url='+encodeURIComponent(Base64.encode(url)));
      let fileName = url.substring(
        url.lastIndexOf("/") + 1,
        url.lastIndexOf(".")
      );
      this.fileUrl =
        "https://admin.pulijiaoyu.org.cn/Upload/" +
        fileName +
        "/" +
        fileName +
        ".html";
      window.open(this.fileUrl);
    },
  },
};
</script>

<style lang="less" scoped>
.download_details {
  .top {
    min-width: 1200px;
    // height: 463px;
    // background-size: 100% 100%;
    // margin-top: -30px;
    .top_cont {
      // height: 300px;
      background-color: #ffffff;
      padding: 40px 30px;
      display: flex;
      .tc_left {
        width: 420px;
        height: 240px;
        margin-right: 40px;
      }
      .tc_right {
        color: #333333;
        width: 760px;
        .title {
          line-height: 58px;
          font-size: 24px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-weight: 400;
        }
        .glkc {
          font-size: 14px;
          margin-top: 16px;
          .el-col {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            .download {
              width: 96px;
              height: 38px;
              text-align: center;
              color: #333333;
              background-color: #eaf0fc;
              /deep/i {
                color: #729efb;
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }
  .cont {
    .title {
      line-height: 60px;
      background-color: #fff;
      padding: 0 30px;
      margin: 10px 0;
      overflow: hidden;
      font-size: 16px;
      margin-bottom: 2px;
      color: #676767;
      position: relative;
      padding-left: 40px;
    }
    .title:after {
      position: absolute;
      width: 2px;
      height: 20px;
      background: #3c81f9;
      left: 30px;
      top: 20px;
      content: "";
    }
    .detail {
      line-height: 150%;
      background-color: #fff;
      padding: 10px 30px;
      // text-indent: 12px;
      min-height: 200px;
      margin-bottom: 50px;
    }
    .noData {
      padding-bottom: 100px !important;
    }
  }
}
</style>